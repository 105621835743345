import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';
import { convertIdToText } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class MemberListViewModel {
  constructor() {
    this.boardData = {
      title:'직원목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        {
          title:'이름',
          width:'120px',
          value:'name',
        },
        {
          title:'권한',
          width:'80px',
          value:'role',
          isAlignLeft: true,
          filter: {
            name:'convertIdToText',
            value:'member_role'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'member_role'
            },
            badgeFill: false
          }
        },
        {
          title:'email',
          width:'16%',
          value:'email',
          isEllip: true,
          isAlignLeft: true
        },
        {
          title:'로그인상태',
          width:'',
          value:'loginStatus',
          isEllip: true,
          isAlignLeft: true,
          icon: {
            iconName:'',
            size:16,
            iconColor:'',
            style:{margin:'2px 6px 0 0'},
            iconNameConvert:(value) => convertIdToText(value,'member_login_status_icon'),
            iconColorFilter:{ name:'convertIdToColor', dataListName:'member_login_status_icon'} 
          },
          filter: {
            name:'convertIdToText',
            value:'member_login_status'
          },
          class: { classColorFilter:{ name:'convertIdToColor', dataListName:'member_login_status', prev:'tc' } }
        },
        {
          title:'마지막 로그인 일시',
          width:'140px',
          value:'lastLoginAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
          isMobileTitle:true
        },
        {
          title:'등록 일시',
          width:'140px',
          value:'createdAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
          isMobileTitle:true
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }

  init(){
    this.getMemberList();
  }
  onClickRow(rowId){
    router.push({ name: 'ADMIN_MEMBER_DETAIL', params: { id : rowId } });
  }
  onSearch(){
    this.getMemberList();
  }
  getMemberList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.USER_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}